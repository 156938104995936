import React from "react";
import { FormattedMessage } from "react-intl";

import FileUpload from "~/components/file_upload";
import { PortletTerm } from "~/components/ui/portlet";

export default function PatientNewInstructionsImages({
  displayHeading = true,
  correctionNumber,
}: {
  displayHeading?: boolean;
  correctionNumber?: number;
}) {
  return (
    <div data-matomo-mask data-hj-suppress className="form-body" id="photo-protocol-block">
      {displayHeading ? (
        <label
          className="control-label"
          id="validation-course_id"
          style={{ fontWeight: "900", fontSize: "17px", marginBottom: "10px" }}
        >
          <FormattedMessage id="UPLOAD_FHOTOS" />
          <span className="required" aria-required="true">
            *
          </span>
          <span className="prescription_tooltip_show">
            <i style={{ fontSize: "14px", marginLeft: "4px" }} className="icon-question" />
            <span className="d-inline-block prescription_tooltip">
              <FormattedMessage id="upload.photos.tooltip" />
            </span>
          </span>
        </label>
      ) : null}

      <div className="row">
        <div className="form-group col-md-4">
          <PortletTerm id="photo-profile-label" required>
            <FormattedMessage id="PHOTO_PROFILE" />
          </PortletTerm>

          <FileUpload
            preview
            id="profile"
            semantics="profile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-profile-label"
          />
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="photo-front-smile-label" required>
            <FormattedMessage id="PHOTO_SMILE" />
          </PortletTerm>

          <FileUpload
            preview
            id="full_face_with_smile"
            semantics="full_face_with_smile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-front-smile-label"
          />
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="photo-front-no-smile-label" required>
            <FormattedMessage id="PHOTO_PORTRATE" />
          </PortletTerm>

          <FileUpload
            preview
            id="full_face_without_smile"
            semantics="full_face_without_smile"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-front-no-smile-label"
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4" style={{ marginBottom: 10 }}>
          <PortletTerm id="photo-upper-occlusal-label" required>
            <FormattedMessage id="PHOTO_UPPER_OCCLUSAL" />
          </PortletTerm>

          <FileUpload
            preview
            id="occlusal_view_upper"
            semantics="occlusal_view_upper"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-upper-occlusal-label"
          />
        </div>

        <div className="form-group col-md-4"></div>

        <div className="form-group col-md-4">
          <PortletTerm id="photo-lower-occlusal-label" required>
            <FormattedMessage id="PHOTO_LOWER_OCCLUSAL" />
          </PortletTerm>

          <FileUpload
            preview
            id="occlusal_view_lower"
            semantics="occlusal_view_lower"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lower-occlusal-label"
          />
        </div>
      </div>

      <div className="row" style={{ marginBottom: 10 }}>
        <div className="form-group col-md-4">
          <PortletTerm id="photo-lateral-right-label" required>
            <FormattedMessage id="PHOTO_RIGHT" />
          </PortletTerm>

          <FileUpload
            preview
            id="lateral_view_right"
            semantics="lateral_view_right"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lateral-right-label"
          />
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="photo-intraoral-front-label" required>
            <FormattedMessage id="PHOTO_FRONT" />
          </PortletTerm>

          <FileUpload
            preview
            id="front_view"
            semantics="front_view"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-intraoral-front-label"
          />
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="photo-lateral-left" required>
            <FormattedMessage id="PHOTO_LEFT" />
          </PortletTerm>

          <FileUpload
            preview
            id="lateral_view_left"
            semantics="lateral_view_left"
            patient_id={0}
            correctionNum={correctionNumber}
            ariaLabelledBy="photo-lateral-left"
          />
        </div>
      </div>
    </div>
  );
}
