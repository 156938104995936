import { myFetch } from '~/common/fetch';
import { saveDraftPatientNotification } from '~/components/notifications/save_patient_draft';
import { submitPatientNotification } from "~/components/notifications/submit_patient_notification";
import { API_POST_PATIENT, API_PUT_PATIENT, API_PUT_UNCOMPLETE_PATIENT, API_SUBMIT_PATIENT } from '~/config';
import type { TUserActionNotification } from '~/reducers/dashboard';
import type { TInstructions } from '~/slices/instructions';
import type { AppDispatch, RootState } from '~/store';
import type { TPatient } from "~/types/patient";
import type { TPrescription } from '~/types/prescription';

import { addUserActionNotification } from "./user_notification";

export function patientIsSavingSuccess(bool: boolean) {
  return { type: "PATIENT_SAVE_SUCCESSFULL", patientIsSaving: bool } as const;
}

export type TCreatePatientInstructions = Partial<TInstructions & { prescription: TPrescription; links: string[] }>;

export function createAndSubmitPatient(
  instructions: TCreatePatientInstructions,
  media: RootState["media"],
  media_s3: RootState["media_s3"]
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.ok",
    level: "success",
    position: "tc",
    autoDismiss: 10,
    children: submitPatientNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.create.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await myFetch(API_POST_PATIENT, {
        method: "POST",
        body: JSON.stringify({ ...instructions, media_info: media, s3_media: media_s3 }),
      });
      if (!response.ok) {
        throw new Error("Error trying to create patient");
      }

      let json = (await response.json()) as Pick<TPatient, "first_name" | "last_name" | "patient_id">;

      const response_2 = await myFetch(API_SUBMIT_PATIENT(json.patient_id), { method: "POST" });
      if (!response_2.ok) {
        throw new Error("Error trying to submit patient");
      }

      json = (await response_2.json()) as Pick<TPatient, "first_name" | "last_name" | "patient_id">;

      dispatch(patientIsSavingSuccess(true));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
      dispatch(patientIsSavingSuccess(false));
    } catch (err) {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      throw err;
    }
  };
}

export function createPatient(
  instructions: TCreatePatientInstructions,
  media: RootState["media"],
  media_s3: RootState["media_s3"]
) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.draft.ok",
    level: "success",
    position: "tc",
    autoDismiss: 0,
    children: saveDraftPatientNotification(),
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.create.error",
    level: "error",
    position: "tl",
    autoDismiss: 4,
  };

  return async (dispatch: AppDispatch) => {
    try {
      const response = await myFetch(API_POST_PATIENT, {
        method: "POST",
        body: JSON.stringify({ ...instructions, media_info: media, s3_media: media_s3 }),
      });
      if (!response.ok) {
        throw new Error("Error occurred trying to create patient");
      }
      const json = await response.json();

      dispatch(patientIsSavingSuccess(true));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      dispatch({ type: "ADD_PATIENT_SUCCESSFULL", json });
    } catch (err) {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
      throw err;
    }
  };
}


export function updatePatient(patient_id: TPatient["patient_id"], { instructions, media, media_s3 }) {
  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_UNCOMPLETE_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to update patient');
      }
      return response;
    })
    .then(() => {
      window.location.href = `/pages/patient/${patient_id}`;
    })
    .catch(() => {
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    })
  }
}


export function updateAndSubmitPatient(patient_id: TPatient["patient_id"], { instructions, media, media_s3 }) {
  const NOTIFICATION_MSG_OK: TUserActionNotification = {
    message: "notify.patient.create.ok",
    level: 'success',
    position: 'tc',
    autoDismiss: 10,
    children: submitPatientNotification()
  };

  const NOTIFICATION_MSG_ERROR: TUserActionNotification = {
    message: "notify.patient.update.error",
    level: 'error',
    position: 'tl',
    autoDismiss: 4,
  };

  instructions["media_info"] = media
  instructions["s3_media"] = media_s3

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to update patient');
      }
      return response;
    })
    .then((response) => response.json())
    .then(response =>
      fetch(API_SUBMIT_PATIENT(response.patient_id), {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'include',
      }))
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred trying to submit patient');
      }
      return response;
    })
    .then(() => {
      dispatch(patientIsSavingSuccess(true));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_OK));
      dispatch(patientIsSavingSuccess(false));
    })
    .catch(() => {
      dispatch(patientIsSavingSuccess(false));
      dispatch(addUserActionNotification(NOTIFICATION_MSG_ERROR));
    });
  }
}


export function updateMedia(patient_id, { media, media_s3, links = null }) {
  const instructions = {}
  instructions["media_info"] = media
  instructions["s3_media"] = media_s3
  instructions["links"] = links

  return (dispatch: AppDispatch) => {
    fetch(API_PUT_PATIENT(patient_id), {
      method: 'PUT',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      credentials: 'include',
      body: JSON.stringify(instructions)
    })
    .then((response) => {
      if (!response.ok) {
        throw new Error('Error occurred tryint to update media');
      }
      return response;
    })
    .then((response) => response.json())
    .then(
      json => {
        dispatch({type: 'UPDATE_PATIENT_SUCCESSFULL', json});
      }
    ).catch(() => {});
  }
}
