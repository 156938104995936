import React from "react";
import { FormattedMessage } from "react-intl";

import { ElasticRecoilLeft, ElasticRecoilRight } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { ValueOf } from "~/types/common";

import PlanCorrectionButtonsHooksLeft from "./recipe-elastics-buttons-hooks-left";
import PlanCorrectionButtonsHooksRight from "./recipe-elastics-buttons-hooks-right";
import { RecipeCheckbox } from "./recipe-shared";

export function RecipeElastics({
  elastics_recoil_right,
  elastics_recoil_left,
  elastics_options,
  resetElasticsOptionsRight,
  resetElasticsOptionsLeft,
  resetForm,
}: {
  elastics_recoil_right: ValueOf<typeof ElasticRecoilRight> | null | undefined;
  elastics_recoil_left: ValueOf<typeof ElasticRecoilLeft> | null | undefined;
  elastics_options: number[];
  resetElasticsOptionsRight(e: React.ChangeEvent<HTMLInputElement>, key: string): void;
  resetElasticsOptionsLeft(e: React.ChangeEvent<HTMLInputElement>, key: string): void;
  resetForm(...args: string[]): void;
}) {
  return (
    <div id="elastics-section" style={{ marginTop: 15 }}>
      <PortletTerm>
        <span id="elastics-heading">
          <FormattedMessage id="HEADER_ELASTICS" />
        </span>

        <span className="prescription_tooltip_show">
          <i style={{ fontSize: "14px", marginLeft: "4px" }} className="icon-question" />

          <span className="d-block prescription_tooltip elastic_tooltip">
            <FormattedMessage id="elastics.tooltip" />

            <a rel="noopener noreferrer" target="_blank" href="/api/v1/files/limits.pdf">
              <FormattedMessage id="tooltip.chart" />
            </a>
          </span>
        </span>
      </PortletTerm>

      <div className="row">
        <div className="form-group col-md-4" style={{ marginBottom: 20 }}>
          <PortletTerm id="elastics-ratio-right-label">
            <FormattedMessage id="ELASTICS_RIGHT" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="elastics_recoil_right"
              value={ElasticRecoilRight.CLASS_2}
              intlId="ER_R_II_CLASS"
              ariaLabelledBy={["elastics-heading", "elastics-ratio-right-label"]}
              onChange={(e) => resetElasticsOptionsRight(e, "elastics_options")}
            />

            <RecipeCheckbox
              name="elastics_recoil_right"
              value={ElasticRecoilRight.CLASS_3}
              intlId="ER_R_III_CLASS"
              ariaLabelledBy={["elastics-heading", "elastics-ratio-right-label"]}
              onChange={(e) => resetElasticsOptionsRight(e, "elastics_options")}
            />
          </div>
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="elastics-ratio-left-label">
            <FormattedMessage id="ELASTICS_LEFT" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="elastics_recoil_left"
              value={ElasticRecoilLeft.CLASS_2}
              intlId="ER_L_II_CLASS"
              ariaLabelledBy={["elastics-heading", "elastics-ratio-left-label"]}
              onChange={(e) => resetElasticsOptionsLeft(e, "elastics_options")}
            />

            <RecipeCheckbox
              name="elastics_recoil_left"
              value={ElasticRecoilLeft.CLASS_3}
              intlId="ER_L_III_CLASS"
              ariaLabelledBy={["elastics-heading", "elastics-ratio-left-label"]}
              onChange={(e) => resetElasticsOptionsLeft(e, "elastics_options")}
            />
          </div>
        </div>
      </div>

      {elastics_recoil_right || elastics_recoil_left ? (
        <div>
          <PortletTerm id="elastics-buttons-and-hooks">
            <FormattedMessage id="BUTTONS_AND_HOOKS" />
          </PortletTerm>
          <br />

          <div className="button_hook_teeth_check">
            <PlanCorrectionButtonsHooksRight
              defaultValue={elastics_options}
              resetForm={resetForm}
              elastics_recoil_right={elastics_recoil_right}
            />

            <PlanCorrectionButtonsHooksLeft
              defaultValue={elastics_options}
              resetForm={resetForm}
              elastics_recoil_left={elastics_recoil_left}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
