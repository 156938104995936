import "../css/FileInput.css";

import React, { Component } from "react";
import Dropzone from "react-dropzone";

type FileInputProps = React.PropsWithChildren<{
  readFolders: boolean;
  hideSelected: boolean;
  onInput?: (files: File[], event: React.ChangeEvent) => void;
}>;

type FileInputState = {
  dragover: boolean;
  files: string[];
};

export default class FileInput extends Component<FileInputProps, FileInputState> {
  files: File[] = [];

  constructor(props: FileInputProps) {
    super(props);
    this.state = {
      dragover: false,
      files: [],
    };

    this.onDrop = this.onDrop.bind(this);
    this.onDragEnter = this.onDragEnter.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
  }

  get className() {
    return ["FileInput", this.state.dragover && "FileInput--active"].filter((v) => v).join(" ");
  }

  onDragEnter() {
    this.setState({ dragover: true });
  }

  onDragLeave() {
    if (!this.refs.$el) return;
    this.setState({ dragover: false });
  }

  async onDrop(files: File[], e: React.ChangeEvent) {
    if (this.props.readFolders) {
      const folderFiles = await this.readFolders(e);
      if (folderFiles) files = folderFiles;
    }

    this.files = files;
    this.setState({ files: files.map((v) => v.name) });

    if (this.props.onInput) this.props.onInput(files, e);
    this.onDragLeave();
  }

  async readFolders(e) {
    if (e.type !== "drop") return;
    if (!e.dataTransfer)
      return console.error("You're trying read folders, but browser isn't support it.");
    if (!e.dataTransfer.items) return;

    const files = [];
    const items = e.dataTransfer.items;
    await Promise.all(
      [...items].map(async (item) => {
        const entry = item.webkitGetAsEntry();
        const _files = await this.getEntryFiles(entry);
        files.push(..._files);
      }),
    );

    return files;
  }

  // return flat file list without paths
  async getEntryFiles(entry) {
    if (entry.isFile) {
      return new Promise((resolve) => {
        entry.file((file) => resolve([file]));
      });
    }

    // Directory
    const files = [];
    const reader = entry.createReader();
    const entries = await new Promise((resolve) => {
      return reader.readEntries(resolve);
    });
    await Promise.all(
      entries.map(async (entry) => {
        const _files = await this.getEntryFiles(entry);
        files.push(..._files);
      }),
    );
    return files;
  }

  render() {
    const { files } = this.state;

    let FilesCount = null;
    if (!this.props.hideSelected && files.length) {
      FilesCount = <div className="FileInput__selected">Selected: {files.length}</div>;
    }

    return (
      <div className={this.className} ref="$el">
        <Dropzone
          className="FileInput__zone"
          // accept="image/*"
          onDropAccepted={this.onDrop}
          onDragEnter={this.onDragEnter}
          onDragLeave={this.onDragLeave}
          disabled={this.props.disabled}
          {...this.props.dropzoneProps}
        >
          <div className="FileInput__label">{this.props.children}</div>
        </Dropzone>
        {FilesCount}
      </div>
    );
  }
}
