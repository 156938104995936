import { ElasticTooth, OcclusalPlaneAction } from "~/common/prescription";
import type { TPrescription } from "~/types/prescription";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

export function convertToJSONFullRx(values: TPrescriptionReduxForm): TPrescription {
  const prepared = { ...values };

  const extractions: number[] = [];
  const dontMove: number[] = [];
  const avoidLocks: number[] = [];
  const teething: number[] = [];

  const elastics_options = Object.entries(prepared.elastics_options)
    .filter(([_, value]) => value == ElasticTooth.BUTTON || value == ElasticTooth.HOOK);
  const close_all_gaps_spaces = prepared.close_all_gaps_spaces.map((value) => Object.values(value));

  const extractionsPostfix = "extractions_";
  const dontMovePostfix = "dont_move_";
  const avoidLocksPostfix = "avoid_locks_";
  const teethingPostfix = "teething_";
  const occlusalPlaneRegex = /occlusal_plane_(extrude|intrude)_/;

  Object.keys(values).forEach((key) => {
    if (key.startsWith(extractionsPostfix) && values[key] === true) {
      extractions.push(Number(key.replace(extractionsPostfix, "")));
    } else if (key.startsWith(avoidLocksPostfix) && values[key] === true) {
      avoidLocks.push(Number(key.replace(avoidLocksPostfix, "")));
    } else if (key.startsWith(dontMovePostfix) && values[key] === true) {
      dontMove.push(Number(key.replace(dontMovePostfix, "")));
    } else if (key.startsWith(teethingPostfix) && values[key] === true) {
      teething.push(Number(key.replace(teethingPostfix, "")));
    }
  });

  prepared.extractions = extractions;
  prepared.dont_move = dontMove;
  prepared.avoid_locks = avoidLocks;
  prepared.teething = teething;
  prepared.occlusal_plane_teeth = convertReduxFormOcclusalPlaneTeethToArray(values);

  prepared.elastics_options = Object.fromEntries(elastics_options);
  prepared.close_all_gaps_spaces = Object.fromEntries(close_all_gaps_spaces);
  prepared.material = prepared.material !== null ? Number(prepared.material) : null;
  prepared.arch = prepared.arch !== null ? Number(prepared.arch) : null;

  if (prepared.displacement_lower != null) {
    prepared.displacement_lower = Boolean(prepared.displacement_lower);
  }

  for (const key in prepared) {
    if (
      key.startsWith(avoidLocksPostfix) ||
      key.startsWith(extractionsPostfix) ||
      key.startsWith(dontMovePostfix) ||
      key.startsWith(teethingPostfix) ||
      occlusalPlaneRegex.test(key) ||
      key.startsWith("null")
    ) {
      delete prepared[key];
    }
  }

  // We pass `correction_with_ct` to the backend as a separate argument, it shouldn't be
  // inside the prescription object.
  delete prepared.correction_with_ct;

  if (prepared.occlusal_plane_subaction) {
    prepared.occlusal_plane_action = prepared.occlusal_plane_subaction;
  }
  delete prepared.occlusal_plane_subaction;

  return prepared;
}

export function convertReduxFormOcclusalPlaneTeethToArray(form: TPrescriptionReduxForm): number[] {
  const occlusalPlaneTeeth: number[] = [];
  const occlusalPlaneRegex = /occlusal_plane_(extrude|intrude)_/;

  Object.keys(form).forEach((key) => {
    if (occlusalPlaneRegex.test(key) && form[key] === true) {
      occlusalPlaneTeeth.push(Number(key.replace(occlusalPlaneRegex, "")));
    }
  });

  return occlusalPlaneTeeth;
}

export function initializeBaseInstruction(order: TPrescription): TPrescriptionReduxForm {
  const initialValues = { ...order } as TPrescriptionReduxForm;

  const spaces_array = Object.entries(initialValues.close_all_gaps_spaces).map(([key, value]) => ({
    tooth: Number(key),
    distance: value.toString(),
  }));

  const dont_move = order.dont_move;
  const avoid_locks = order.avoid_locks;
  const extractions = order.extractions;
  const teething = order.teething;
  const occlusalPlaneTeeth = order.occlusal_plane_teeth;

  dont_move.forEach((value) => {
    initialValues[`dont_move_${value}`] = true;
  });
  avoid_locks.forEach((value) => {
    initialValues[`avoid_locks_${value}`] = true;
  });
  extractions.forEach((value) => {
    initialValues[`extractions_${value}`] = true;
  });
  teething.forEach((value) => {
    initialValues[`teething_${value}`] = true;
  });
  occlusalPlaneTeeth.forEach((value) => {
    if (
      initialValues.occlusal_plane_action == null ||
      initialValues.occlusal_plane_action == OcclusalPlaneAction.SAVE
    ) {
      return;
    }

    const suffix = (
      {
        [OcclusalPlaneAction.EXTRUDE]: "extrude",
        [OcclusalPlaneAction.INTRUDE]: "intrude",
      } as const
    )[initialValues.occlusal_plane_action];

    initialValues[`occlusal_plane_${suffix}_${value}`] = true;
  });

  delete initialValues.dont_move;
  delete initialValues.avoid_locks;
  delete initialValues.extractions;
  delete initialValues.teething;

  initialValues.close_all_gaps_spaces = spaces_array.length > 0 ? spaces_array : [{}];
  initialValues.material = initialValues.material !== null ? order.material.toString() : null;
  initialValues.arch = initialValues.arch !== null ? order.arch.toString() : null;

  if (
    initialValues.occlusal_plane_action != null &&
    initialValues.occlusal_plane_action != OcclusalPlaneAction.SAVE
  ) {
    initialValues.occlusal_plane_subaction = initialValues.occlusal_plane_action;
    initialValues.occlusal_plane_action = OcclusalPlaneAction.MODIFY;
  }

  return initialValues;
}
