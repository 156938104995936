import clsx from "clsx";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";
import { Field, FormSection, getFormValues } from "redux-form";

import { createTeeth, type ElasticRecoilRight, ElasticTooth } from "~/common/prescription";
import { convertToDentalNotation } from "~/common/utils";
import type { RootState } from "~/store";
import type { ValueOf } from "~/types/common";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    formValues: getFormValues("correction")(state) as TPrescriptionReduxForm,
  };
};

type PlanCorrectionButtonsHooksRightProps = PropsFromRedux & {
  defaultValue: number[];
  elastics_recoil_right: ValueOf<typeof ElasticRecoilRight> | null | undefined;
  resetForm(...args: string[]): void;
};

type PlanCorrectionButtonHooksRightState = {
  name: string | null;
  isChecked: boolean;
};

class PlanCorrectionButtonsHooksRight extends Component<
  PlanCorrectionButtonsHooksRightProps,
  PlanCorrectionButtonHooksRightState
> {
  teeth: number[][];

  constructor(props: PlanCorrectionButtonsHooksRightProps) {
    super(props);
    this.teeth = createTeeth();
    this.state = {
      name: null,
      isChecked: false,
    };
    this.setClassName = this.setClassName.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e: React.ChangeEvent<HTMLInputElement>, i: number) {
    if (e.target.checked) {
      this.setState({ name: e.target.name, isChecked: true });
    } else{
      this.setState({ name: null, isChecked: false });
      this.props.resetForm(`elastics_options.${i}`);
    }
  }

  setClassName(i: number) {
    const { elastics_options } = this.props.formValues;

    if (elastics_options[i] == ElasticTooth.BUTTON) {
      return "button_hook_tip button";
    } else if (elastics_options[i] == ElasticTooth.HOOK) {
      return "button_hook_tip hook";
    } else if (!this.props.elastics_recoil_right) {
      return "button_hook_tip input_button elastic_disabled";
    } else {
      return "button_hook_tip input_button";
    }
  }

  componentDidUpdate(prevProps: PlanCorrectionButtonsHooksRightProps) {
    if (prevProps.formValues !== this.props.formValues) {
      this.forceUpdate();
    }
  }

  render() {
    const allTeeth = this.teeth.map(this.renderQuadrant.bind(this));
    const upperTeeth = allTeeth.slice(0, 1);
    const lowerTeeth = allTeeth.slice(2, 3);

    return (
      <div>
        <div style={{ marginBottom: "0.5rem" }} className="teeth-controls-upper">
          {upperTeeth}
        </div>

        <div className="teeth-controls-lower">{lowerTeeth}</div>
      </div>
    );
  }

  renderQuadrant(quad: number[], i: number) {
    const teeth = quad.map(this.renderTooth.bind(this));

    return (
      <div key={i} style={{ borderLeft: "none" }} className="checkbox-list teeth-controls">
        {teeth}
      </div>
    );
  }

  renderTooth(i: number) {
    const { name, isChecked } = this.state;
    const { user, defaultValue = [], elastics_recoil_right } = this.props;
    const dentalNotation = user.preferences?.dental_notation;
    const { elastics_options } = this.props.formValues;

    return (
      <label key={i}>
        {convertToDentalNotation(i, dentalNotation)}

        <div className={this.setClassName(i)}>
          {elastics_options[i] == ElasticTooth.BUTTON ||
          elastics_options[i] == ElasticTooth.HOOK ? (
            <FormattedMessage id="CANCEL" tagName="span" />
          ) : null}

          <input
            disabled={elastics_recoil_right ? false : true}
            type="checkbox"
            name={`options_${i}`}
            id={`options-${i}`}
            aria-labelledby={clsx("elastics-buttons-and-hooks", `options-${i}`)}
            onChange={(e) => this.handleChange(e, i)}
            defaultChecked={defaultValue.includes(i)}
          />
        </div>

        {name == `options_${i}` && isChecked && elastics_recoil_right ? (
          <FormSection name="elastics_options" className="button_hook">
            <div>
              <Field
                style={{ marginBottom: "10px" }}
                component="input"
                name={`${i}`}
                type="radio"
                id={`button${i}`}
                className="tw-sr-only"
                value={ElasticTooth.BUTTON}
                normalize={Number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ name: e.target.name })
                }
              />
              &nbsp;
              <label htmlFor={`button${i}`}>
                <FormattedMessage id="BUTTON" tagName="span" />
              </label>
            </div>

            <div>
              <Field
                component="input"
                name={`${i}`}
                type="radio"
                id={`hook${i}`}
                className="tw-sr-only"
                value={ElasticTooth.HOOK}
                normalize={Number}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  this.setState({ name: e.target.name })
                }
              />
              &nbsp;
              <label htmlFor={`hook${i}`}>
                <FormattedMessage id="HOOK" tagName="span" />
              </label>
            </div>
          </FormSection>
        ) : null}
      </label>
    );
  }
}

const connector = connect(mapStateToProps, null);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PlanCorrectionButtonsHooksRight);
