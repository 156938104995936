import React from "react";
import { FormattedMessage } from "react-intl";

import { translateRxOption } from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

import { PatientCardElasticsTeethItems } from "./patient-card-teeth-items";

export function PatientCardElastics({
  user,
  prescription,
}: {
  user: TUser;
  prescription: TPrescription;
}) {
  const { elastics_recoil_right, elastics_recoil_left, elastics_options } = prescription;
  const elastics = Object.keys(elastics_options).map((i) => parseInt(i));

  return (
    <div>
      <PortletTerm>
        <FormattedMessage id="HEADER_ELASTICS" />
      </PortletTerm>

      <div className="row">
        <div className="form-group col-md-4">
          <PortletTerm id="elastics-ratio-right-label">
            <FormattedMessage id="ELASTICS_RIGHT" />
          </PortletTerm>

          <p id="elastics-ratio-right-value">
            <FormattedMessage
              id={translateRxOption("elastics_recoil_right", elastics_recoil_right)}
            />
          </p>
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="elastics-ratio-left-label">
            <FormattedMessage id="ELASTICS_LEFT" />
          </PortletTerm>

          <p id="elastics-ratio-left-value">
            <FormattedMessage
              id={translateRxOption("elastics_recoil_left", elastics_recoil_left)}
            />
          </p>
        </div>
      </div>

      {elastics.length > 0 ? (
        <PatientCardElasticsTeethItems
          label="BUTTONS_AND_HOOKS"
          name="elastics_options"
          notation={user.preferences.dental_notation}
          prescription={prescription}
        />
      ) : null}
    </div>
  );
}
