import clsx from "clsx";
import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";

import { getClinics } from "~/actions/clinics";
import { Status } from "~/common/courses";
import { PortletTerm } from "~/components/ui/portlet";
import { addInstructions } from "~/slices/instructions";
import { useAppDispatch, useAppSelector } from "~/store";
import type { TClinic } from "~/types/clinic";
import type { TPatient } from "~/types/patient";
import type { SetDirtyFn } from "~/types/patient-new";

export default function PatientUpdateDoctorInfo({
  patient,
  setDirty,
}: {
  patient: TPatient;
  setDirty: SetDirtyFn;
}) {
  const dispatch = useAppDispatch();
  const clinics = useAppSelector((state) => state.clinics);

  useEffect(() => {
    // Store `clinic_id` inside `instructions`
    if (patient.clinic.clinic_id) {
      dispatch(addInstructions({ clinic_id: patient.clinic.clinic_id }));
    }
  }, [dispatch, patient.clinic.clinic_id]);

  useEffect(() => {
    // Load clinics
    void dispatch(getClinics());
  }, [dispatch]);

  function selectClinic(clinic_id: TClinic["clinic_id"]) {
    dispatch(addInstructions({ clinic_id }));
    setDirty("clinic_id", clinic_id);
  }

  // NOTE: without this patient_update doesn't work. This absolutely horrible!
  const firstCorrection = patient.course.correction[0];

  if (firstCorrection) {
    for (const key of Object.keys(firstCorrection)) {
      if (firstCorrection[key] == "None" || firstCorrection[key] == "NA") {
        firstCorrection[key] = "";
      }
    }
  }

  return (
    <div>
      <h3 className="block" style={{ fontWeight: "900", marginTop: "0" }}>
        <FormattedMessage id="BLOCKHEAD_DOCTOR" />
      </h3>

      <div className="form-group" id="clinic-section">
        <PortletTerm id="validation-clinic_id" required={patient.status == Status.UNFILLED_CASE}>
          <FormattedMessage id="HEADER_CLINIC" />
        </PortletTerm>

        {patient.status == Status.UNFILLED_CASE ? (
          <div className="radio-list">
            {clinics.map((clinic) => (
              <label key={clinic.clinic_id}>
                <div className="radio">
                  <input
                    id={`clinic-${clinic.clinic_id}`}
                    aria-labelledby={clsx("validation-clinic_id", `clinic-${clinic.clinic_id}`)}
                    type="radio"
                    name="clinic"
                    defaultChecked={patient.clinic.clinic_id == clinic.clinic_id}
                    value={clinic.clinic_id}
                    onChange={(e) => selectClinic(Number(e.target.value))}
                  />
                </div>
                {clinic.title}
              </label>
            ))}
          </div>
        ) : (
          <p>{patient.clinic.title}</p>
        )}
      </div>
    </div>
  );
}
