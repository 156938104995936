import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Field } from "redux-form";

import { isChildrenCourse } from "~/common/courses";
import {
  IncisorsLowerHorizontalOverlap,
  IncisorsUpperHorizontalOverlap,
  IncisorsVerticalOverlap,
  Midline,
  Overjet,
} from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TCourse } from "~/types/patient";
import type { TPrescriptionReduxForm } from "~/types/redux-form";

import { RecipeCheckbox } from "./recipe-shared";

export function RecipeIncisors({
  course_id,
  vertical_overlap,
  midline,
}: {
  course_id: TCourse["course_id"] | null | undefined;
  vertical_overlap: TPrescriptionReduxForm["vertical_overlap"] | undefined;
  midline: TPrescriptionReduxForm["midline"] | undefined;
}) {
  return (
    <div id="incisors-section">
      <h4 className="block" style={{ fontWeight: "900" }}>
        <FormattedMessage id="HEADER_INCISORS" />
      </h4>

      <VerticalOverlapOptions vertical_overlap={vertical_overlap} />

      {isChildrenCourse(course_id) ? null : (
        <>
          <HorizontalOverlapOptions />
          <OverjetOptions />
          <DisplacementLowerOptoins />
          <MidlineOptions midline={midline} />
        </>
      )}
    </div>
  );
}

function VerticalOverlapOptions({
  vertical_overlap,
}: {
  vertical_overlap: TPrescriptionReduxForm["vertical_overlap"] | undefined;
}) {
  const intl = useIntl();

  return (
    <>
      <div className="form-group" style={{ marginBottom: 20 }}>
        <PortletTerm id="incisors-vert-overlap-label">
          <FormattedMessage id="INCISORS_VO" />
        </PortletTerm>

        <div className="checkbox-list checkbox_list">
          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.MAINTAIN}
            intlId="VO_MAINTAIN"
            ariaLabelledBy="incisors-vert-overlap-label"
          />

          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.DECREASE}
            intlId="VO_DECREASE"
            ariaLabelledBy="incisors-vert-overlap-label"
          />

          <RecipeCheckbox
            name="vertical_overlap"
            value={IncisorsVerticalOverlap.INCREASE}
            intlId="VO_INCREASE"
            ariaLabelledBy="incisors-vert-overlap-label"
          />
        </div>
      </div>

      {vertical_overlap == IncisorsVerticalOverlap.DECREASE ||
      vertical_overlap == IncisorsVerticalOverlap.INCREASE ? (
        <div>
          <div className="form-group">
            <label
              htmlFor="vertical_overlap_comment"
              className="control-label"
              id="validation-vertical_overlap_comment"
              style={{ fontWeight: "900" }}
            >
              <FormattedMessage id="VO_COMMENT" />
              <span className="required" aria-required="true">
                *
              </span>
            </label>

            <Field
              style={{ width: "60%" }}
              component="textarea"
              className="form-control"
              id="vertical_overlap_comment"
              rows={5}
              name="vertical_overlap_comment"
              placeholder={intl.formatMessage({ id: "VO_COMMENT_PLACEHOLDER" })}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

function HorizontalOverlapOptions() {
  return (
    <div>
      <PortletTerm id="incisors-horiz-overlap-label">
        <FormattedMessage id="INCISORS_HO" />
      </PortletTerm>

      <div className="row">
        <div className="form-group col-md-4" style={{ marginBottom: "20px" }}>
          <PortletTerm id="incisors-upper-horiz-overlap-label">
            <FormattedMessage id="INCISORS_HO_U" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.MAINTAIN}
              intlId="HO_U_MAINTAIN"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.RETROCLINATE}
              intlId="HO_U_RETROCLINATE"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="upper_horizontal_overlap"
              value={IncisorsUpperHorizontalOverlap.PROCLINATE}
              intlId="HO_U_PROCLINATE"
              ariaLabelledBy="incisors-upper-horiz-overlap-label"
            />
          </div>
        </div>

        <div className="form-group col-md-4">
          <PortletTerm id="incisors-lower-horiz-overlap-label">
            <FormattedMessage id="INCISORS_HO_L" />
          </PortletTerm>

          <div className="checkbox-list checkbox_list">
            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.MAINTAIN}
              intlId="HO_L_MAINTAIN"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.RETROCLINATE}
              intlId="HO_L_RETROCLINATE"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />

            <RecipeCheckbox
              name="lower_horizontal_overlap"
              value={IncisorsLowerHorizontalOverlap.PROCLINATE}
              intlId="HO_L_PROCLINATE"
              ariaLabelledBy="incisors-lower-horiz-overlap-label"
            />
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

function OverjetOptions() {
  return (
    <div className="form-group">
      <PortletTerm id="overjet-label">
        <FormattedMessage id="OVERJET" />
      </PortletTerm>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="overjet"
          value={Overjet.MAINTAIN}
          intlId="OJ_MAINTAIN"
          ariaLabelledBy="overjet-label"
        />

        <RecipeCheckbox
          name="overjet"
          value={Overjet.CONTACT}
          intlId="OJ_CONTACT"
          ariaLabelledBy="overjet-label"
        />

        <RecipeCheckbox
          name="overjet"
          value={Overjet.MAINTAIN_LARGE}
          intlId="OJ_MAINTAIN_LARGE"
          ariaLabelledBy="overjet-label"
        />
      </div>
    </div>
  );
}

function DisplacementLowerOptoins() {
  return (
    <div className="form-group">
      <PortletTerm id="displacement_lower-heading">
        <FormattedMessage id="DISPLACEMENT_LOWER" />
      </PortletTerm>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="displacement_lower"
          value={Number(true)}
          intlId="bool.true"
          ariaLabelledBy="displacement_lower-heading"
        />

        <RecipeCheckbox
          name="displacement_lower"
          value={Number(false)}
          intlId="bool.false"
          ariaLabelledBy="displacement_lower-heading"
        />
      </div>
    </div>
  );
}

function MidlineOptions({ midline }: { midline: TPrescriptionReduxForm["midline"] | undefined }) {
  return (
    <div className="form-group">
      <PortletTerm id="validation-midline">
        <FormattedMessage id="MIDLINE" />
      </PortletTerm>

      <div className="checkbox-list checkbox_list">
        <RecipeCheckbox
          name="midline"
          value={Midline.MAINTAIN}
          intlId="ML_MAINTAIN"
          ariaLabelledBy="validation-midline"
        />

        <RecipeCheckbox
          name="midline"
          value={Midline.IMPROVE}
          intlId="ML_IMPROVE"
          ariaLabelledBy="validation-midline"
        />

        <div className="form-inline">
          <Field
            aria-labelledby={`midline-value-${Midline.IMPROVE}`}
            component="input"
            type="text"
            maxLength="512"
            size={32}
            disabled={midline !== Midline.IMPROVE}
            className="form-control"
            name="midline_improve_by"
          />
        </div>
      </div>
    </div>
  );
}
