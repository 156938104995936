import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { remoteLog } from "~/common/logging";
import FileUpload from "~/components/file_upload";
import type { RootState } from "~/store";

const formatFileName = (filename: string) => {
  const fileName = filename.split("/");
  return fileName[fileName.length - 1];
};

const mapStateToProps = (state: RootState) => {
  return {
    patient: state.patient,
  };
};

type PatientUpdateInstructionsNewMediaProps = PropsFromRedux & {
  has_medit_files: boolean;
  showUploadedFiles?: boolean;
};

type PatientUpdateInstructionsNewMediaState = {
  files_counter: number;
  has_medit_files: boolean;
};

class PatientUpdateInstructionsNewMedia extends Component<
  PatientUpdateInstructionsNewMediaProps,
  PatientUpdateInstructionsNewMediaState
> {
  renders: React.JSX.Element[];

  constructor(props: PatientUpdateInstructionsNewMediaProps) {
    super(props);
    this.state = {
      files_counter: 1,
      has_medit_files: props.has_medit_files || false,
    };
    this.addFileUpload = this.addFileUpload.bind(this);
    this.renders = [
      <FileUpload
        patient_id={0}
        onFileUploadEnd={() => this.addFileUpload()}
        key={`recipe_${0}`}
        id={`recipe_${0}`}
        semantics="recipe"
        ariaLabelledBy="instruction-files"
      />,
    ];
  }

  componentDidCatch(e: Error) {
    remoteLog(e, "patient_update_instructions_new_media");
  }

  addFileUpload() {
    const next = this.renders.length;
    this.renders.push(
      <FileUpload
        patient_id={0}
        onFileUploadEnd={() => this.addFileUpload()}
        key={`recipe_${next}`}
        id={`recipe_${next}`}
        semantics="recipe"
        ariaLabelledBy="instruction-files"
      />,
    );
    this.forceUpdate();
  }

  render() {
    const filesUploaded =
      this.props.patient.media &&
      this.props.patient.media.optional_media.length > 0 &&
      this.props.showUploadedFiles;
    const pmo = filesUploaded ? this.props.patient.media.optional_media : [];

    return (
      <div className="form-group">
        <div data-matomo-mask data-hj-suppress className="form-body" id="extra-media-block">
          <h4 className="block" id="instruction-files" style={{ fontWeight: "900" }}>
            <FormattedMessage id="UPLOAD_FILES" />
          </h4>
        </div>

        <div className="row">
          <div className="col-xs-4">{this.renders}</div>
          {filesUploaded ? (
            <div
              data-matomo-mask
              data-hj-suppress
              style={{ marginTop: "12px" }}
              className="col-xs-12 col-sm-12 col-md-12"
            >
              <h6 style={{ fontWeight: 900 }}>
                <FormattedMessage id="CBCT_UPLOADED_HEADER" />
              </h6>
              <table
                role="presentation"
                className="table table-striped clearfix"
                style={{ wordBreak: "break-all" }}
                cellSpacing={0}
                cellPadding={0}
              >
                <tbody className="files">
                  {this.state.has_medit_files === true ? (
                    <tr>
                      <td>
                        <p>
                          <FormattedMessage id="th.medit.upload_success" />
                        </p>
                      </td>
                    </tr>
                  ) : null}
                  {pmo.map((m) => (
                    <tr key={m.linkto} className="template-download fade in">
                      <td>
                        <p>{formatFileName(m.linkto)}</p>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : null}
        </div>
      </div>
    );
  }
}

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientUpdateInstructionsNewMedia);
