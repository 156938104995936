import React from "react";
import { FormattedMessage } from "react-intl";

import { isChildrenCourse, isRetainersCourse } from "~/common/courses";
import {
  getOcclusalPlaneTeeth,
  OcclusalPlane,
  OcclusalPlaneAction,
  translateRxOption,
  UpperArch,
} from "~/common/prescription";
import { PortletTerm } from "~/components/ui/portlet";
import type { TPatient } from "~/types/patient";
import type { TPrescription } from "~/types/prescription";
import type { TUser } from "~/types/user";

import { PatientCardToothItem } from "./patient-card-teeth-items";

export function PatientCardTreatArches({
  user,
  course_id,
  prescription,
}: {
  user: TUser;
  course_id: TPatient["course"]["course_id"];
  prescription: TPrescription;
}) {
  const {
    arch,
    teeth_level_align,
    teeth_level_align_lower,
    occlusal_plane,
    occlusal_plane_action,
    occlusal_plane_teeth,
  } = prescription;

  return (
    <div>
      <h4 id="treat-arches-section" className="block" style={{ fontWeight: "500" }}>
        <FormattedMessage id="HEADER_ARCHES" />
      </h4>

      <PortletTerm id="treat-arches-label">
        <FormattedMessage id="ARCHES_SELECT" />
      </PortletTerm>

      <p id="treat-arches-value">
        <FormattedMessage id={translateRxOption("arch", arch)} />
      </p>

      {isRetainersCourse(course_id) ? null : (
        <>
          <PortletTerm id="upper-arch-action-label">
            <FormattedMessage id="ARCHES_U_SELECT" />
          </PortletTerm>

          {renderExpansion({ arch: "upper", prescription })}

          <PortletTerm>
            <FormattedMessage id="ARCHES_L_SELECT" />
          </PortletTerm>

          {renderExpansion({ arch: "lower", prescription })}

          {isChildrenCourse(course_id) ? null : (
            <>
              <PortletTerm id="teeth-level-label">
                <FormattedMessage id="ARCHES_TLA_UPPER" />
              </PortletTerm>

              <p id="teeth-level-value">
                <FormattedMessage id={translateRxOption("teeth_level_align", teeth_level_align)} />
              </p>

              {teeth_level_align_lower ? (
                <>
                  <PortletTerm id="teeth-level-label">
                    <FormattedMessage id="ARCHES_TLA_LOWER" />
                  </PortletTerm>

                  <p id="teeth-level-value">
                    <FormattedMessage
                      id={translateRxOption("teeth_level_align", teeth_level_align_lower)}
                    />
                  </p>
                </>
              ) : null}
            </>
          )}

          {occlusal_plane != null ? (
            <>
              <PortletTerm>
                <FormattedMessage id="TA_OCCLUSAL" />
              </PortletTerm>

              <div className="tw-space-y-2.5">
                <p className="tw-m-0">
                  <FormattedMessage id={translateRxOption("occlusal_plane", occlusal_plane)} />
                </p>

                {occlusal_plane != OcclusalPlane.PARALLEL ? (
                  <p className="tw-m-0 tw-flex tw-gap-4">
                    <FormattedMessage
                      id={translateRxOption("occlusal_plane_action", occlusal_plane_action)}
                    />

                    {occlusal_plane_action == OcclusalPlaneAction.EXTRUDE ||
                    (occlusal_plane_action == OcclusalPlaneAction.INTRUDE &&
                      occlusal_plane_teeth.length > 0) ? (
                      <div className="checkbox-list teeth-controls tw-relative tw-bottom-3 -tw-mb-7 tw-border-none">
                        {getOcclusalPlaneTeeth(occlusal_plane, occlusal_plane_action).map(
                          (tooth) => (
                            <PatientCardToothItem
                              key={tooth}
                              tooth={tooth}
                              notation={user.preferences.dental_notation}
                              defaultChecked={occlusal_plane_teeth.includes(tooth)}
                            />
                          ),
                        )}
                      </div>
                    ) : null}
                  </p>
                ) : null}
              </div>
            </>
          ) : null}
        </>
      )}
    </div>
  );
}

function renderExpansion({
  arch,
  prescription,
}: {
  arch: "lower" | "upper";
  prescription: TPrescription;
}) {
  if (prescription[`${arch}_arch_action`] == UpperArch.EXPAND) {
    if (
      prescription[`${arch}_arch_expansion_bodily`] &&
      prescription[`${arch}_arch_expansion_torque`]
    ) {
      return (
        <p id={`${arch}-arch-action`}>
          <FormattedMessage
            id={translateRxOption(`${arch}_arch_action`, prescription[`${arch}_arch_action`])}
          />
          &nbsp;(
          <FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />, &nbsp;
          <FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />)
        </p>
      );
    }

    if (prescription[`${arch}_arch_expansion_bodily`]) {
      return (
        <p id={`${arch}-arch-action`}>
          <FormattedMessage
            id={translateRxOption(`${arch}_arch_action`, prescription[`${arch}_arch_action`])}
          />
          &nbsp;(
          <FormattedMessage id="TA_U_EXPAND_TYPE_BODILY" />)
        </p>
      );
    }

    if (prescription[`${arch}_arch_expansion_torque`]) {
      return (
        <p id={`${arch}-arch-action`}>
          <FormattedMessage
            id={translateRxOption(`${arch}_arch_action`, prescription[`${arch}_arch_action`])}
          />
          &nbsp;(
          <FormattedMessage id="TA_U_EXPAND_TYPE_TORQUE" />)
        </p>
      );
    }
  }

  return (
    <p>
      <FormattedMessage
        id={translateRxOption(`${arch}_arch_action`, prescription[`${arch}_arch_action`])}
      />
    </p>
  );
}
