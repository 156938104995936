export function myFetch(url: string, options: RequestInit = {}): Promise<Response> {
  const defaultHeaders = {
    "Accept": "application/json",
    "Content-Type": "application/json",
  };

  const mergedOptions: RequestInit = {
    ...options,
    headers: { ...defaultHeaders, ...options.headers, },
    credentials: "include",
  };

  return fetch(url, mergedOptions);
}
