import JSZip from "jszip";
import React, { Component } from "react";
import { FormattedMessage } from "react-intl";
import { connect, type ConnectedProps } from "react-redux";

import { cbctUploadSuccess } from "~/actions/dashboard";
import { addUserActionNotification } from "~/actions/user_notification";
import FileUploader from "~/common/async/FileUploader";
import { CBCT_MAX_FILESIZE, FileType, MB } from "~/common/constants";
import { roundFloat } from "~/common/math";
import type { RootState } from "~/store";

import FileInput from "../../FileInput";
import Notification from "../../notification";
import PatientFiles from "../patient_files";

const NOTIFY_ERROR = {
  message: "",
  level: "error",
  position: "tl",
  autoDismiss: "8",
};

const mapStateToProps = (state: RootState) => {
  return {
    user: state.user,
    patient: state.patient,
    patientClinic: state.patient && state.patient.clinic,
    clinics: state.clinics,
    instructions: state.instructions,
    media_s3: state.media_s3,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clearMediaS3: () => dispatch({ type: "media_s3/clear" }),
    cbctUploadSuccess: (files) => dispatch(cbctUploadSuccess(files)),
    addUserActionNotification: (msg) => dispatch(addUserActionNotification(msg)),
  };
};

type PatientUpdateInstructionsUploadCtProps = PropsFromRedux & { showUploadedFiles?: boolean; };

type PatientUpdateInstructionsUploadCtState = {
  filesCount: number;
  showProgress: boolean;
  uploaderStatus: number;
  error: unknown;
  loadedSize: number;
  totalSize: number;
  progressPerc: string;
};

class PatientUpdateInstructionsUploadCt extends Component<
  PatientUpdateInstructionsUploadCtProps,
  PatientUpdateInstructionsUploadCtState
> {
  uploader: FileUploader;
  files?: File[];

  constructor(props: PatientUpdateInstructionsUploadCtProps) {
    super(props);
    this.state = {
      filesCount: 0,
      showProgress: false,
      uploaderStatus: 0,
      error: null,
      loadedSize: 0,
      totalSize: 0,
      progressPerc: "0%",
    };
    this.uploader = new FileUploader();
    this.uploader.on("progress", this.onUploadProgress.bind(this));
    this.uploader.on("finish", this.onUploadSuccess.bind(this));
    this.uploader.on("error", this.onUploadError.bind(this));
    this.inputFiles = this.inputFiles.bind(this);
    this.showModal = this.showModal.bind(this);
  }

  componentDidMount() {
    this.props.clearMediaS3();
  }

  get clinic() {
    if (this.props.instructions && this.props.instructions.clinic_id) {
      if (!this.props.clinics) return;
      return this.props.clinics.find((v) => v.clinic_id === this.props.instructions.clinic_id);
    }

    if (this.props.patientClinic) {
      return this.props.patientClinic;
    }
  }

  async inputFiles(files: File[]) {
    if (!files.length) return;

    // Check files size
    const totalSize = files.reduce((sum, file) => sum + file.size, 0);
    if (totalSize > CBCT_MAX_FILESIZE) {
      return this.props.addUserActionNotification({
        ...NOTIFY_ERROR,
        message: "notify.upload.E_FILE_TOO_BIG_CBCT",
      });
    }

    // Check file types
    const regDicom = /\.(zip|7z|rar)$/i;
    const wrongFile = files.some((file) => {
      return !regDicom.test(file.name);
    });
    if (wrongFile) {
      return this.props.addUserActionNotification({
        ...NOTIFY_ERROR,
        message: "notify.upload.E_UNSUPPORTED_FORMAT_CBCT",
      });
    }

    // Separate archives
    const archives = [];
    const regArchive = /\.(zip|7z|rar)$/i;
    files = files.filter((file) => {
      if (regArchive.test(file.name)) {
        archives.push(file);
        return false;
      }
      return file;
    });

    // Zip files
    let archive;
    if (files.length > 0) {
      archive = await this.zipFiles(files);
      const date = new Date().toISOString().split("T")[0];
      archive.name = `${date}.cbct.zip`;
    }

    files = [...archives, archive].filter((v) => v);

    await this.upload(files);
  }

  async zipFiles(files) {
    const zip = new JSZip();

    files.forEach((file) => {
      zip.file(file.name, file);
    });

    return await zip.generateAsync({
      type: "blob",
      // compression isn't necessary
      compression: "DEFLATE",
      compressionOptions: {
        level: 6,
      },
    });
  }

  async upload(files: File[] = []) {
    if (!files.length) return;

    this.setState({ showProgress: true, filesCount: files.length });
    this.lockForm();

    let links;
    try {
      links = await this.getFileLinks(files);
    } catch (error) {
      this.files = files;
      this.onUploadError(error);
      return;
    }

    files.forEach((file) => {
      this.uploader.upload(links[file.name], file);
    });
  }

  async getFileLinks(files: File[] = []) {
    const clinicInfo = this.clinic;
    const body = {
      uuid: this.props.media_s3.uuid,
      clinic_id: clinicInfo ? this.clinic.clinic_id : null,
      files: files.map((file) => {
        return {
          file_name: file.name,
          file_type: "dicom",
          // file_hash: // TODO
        };
      }),
    };

    const res = await fetch(`${process.env.API}/patients/media`, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
    });
    const data = await res.json();
    if (!res.ok) {
      throw data;
    }

    return data;
  }

  onUploadProgress() {
    let { loadedSize, totalSize } = this.uploader;
    loadedSize = roundFloat(loadedSize / MB, 1);
    totalSize = roundFloat(totalSize / MB, 1);
    this.setState({
      loadedSize,
      totalSize,
      progressSizePerc: this.uploader.progressSizePerc,
    });
  }

  onUploadSuccess() {
    this.unlockForm();
    this.setState({ uploaderStatus: this.uploader.promise.status });

    const filesData = this.uploader.fulfilled.map((item) => item.file);
    this.props.cbctUploadSuccess(filesData);
  }

  onUploadError(e: unknown) {
    this.unlockForm();
    this.setState({ uploaderStatus: this.uploader.promise.status, error: e });

    let errorMessage = "E_CONNECTION_FAILED";
    if (e.target && e.target.status) {
      errorMessage = `Network error ${e.target.status}: ${e.target.statusText}`;
    }
    this.props.addUserActionNotification({ ...NOTIFY_ERROR, message: errorMessage });
  }

  lockForm() {
    $("#submit-pacient-btn, #save-pacient-btn").attr("disabled", true);
  }

  unlockForm() {
    $("#submit-pacient-btn, #save-pacient-btn").attr("disabled", false);
  }

  retryUpload(e) {
    e.preventDefault();
    if (this.files) {
      // If this.files exists, then smth wrong with links
      this.upload(this.files);
      this.files = null;
    } else {
      this.uploader.retryAll();
    }

    this.setState({
      uploaderStatus: this.uploader.promise.status,
      error: null,
    });
    this.lockForm();
  }

  showModal() {
    $("#video").modal("show");
  }

  render() {
    return (
      <div className="form-group InstructionsUploadCt">
        <h4>
          <label className="form-label" id="upload-ct" style={{ fontWeight: 900 }}>
            <FormattedMessage id="CBCT_UPLOAD_HEADER" />
          </label>
        </h4>

        <div className="row" style={{ marginTop: "12px" }}>
          {this.state.showProgress ? this.renderProgress() : this.renderFileInput()}
        </div>

        <Notification />
      </div>
    );
  }

  renderProgress() {
    const { uploaderStatus, filesCount, loadedSize, totalSize, progressSizePerc, error } =
      this.state;

    const barClassName = [
      uploaderStatus === 0 ? "progress-bar-primary progress-bar-striped active" : "",
      uploaderStatus === 1 ? "progress-bar-success" : "",
      error ? "progress-bar-danger" : "",
    ].join(" ");

    let progressTitle = (
      <FormattedMessage id="CBCT_UPLOAD_PROGRESS_TITLE" values={{ filesCount }} />
    );
    let retryBtn;
    if (uploaderStatus === 1) {
      progressTitle = (
        <span className="text-success">
          <FormattedMessage id="CBCT_UPLOAD_COMPLETE" />
        </span>
      );
    } else if (error || uploaderStatus === 2) {
      progressTitle = (
        <span className="text-danger">
          <FormattedMessage id="CBCT_UPLOAD_ERROR" />
        </span>
      );
      retryBtn = (
        <button className="btn btn-danger" onClick={this.retryUpload.bind(this)}>
          <FormattedMessage id="general.retry" />
        </button>
      );
    }

    return (
      <div className="col-xs-12 col-sm-8">
        <h4 className="title">{progressTitle}</h4>
        <div className="progress">
          <div className={`progress-bar ${barClassName}`} style={{ width: progressSizePerc }}>
            {loadedSize} MB / {totalSize} MB ({progressSizePerc})
          </div>
        </div>
        {retryBtn}
      </div>
    );
  }

  renderFileInput() {
    const filesUploaded =
      this.props.patient &&
      this.props.patient.s3_media &&
      this.props.patient.s3_media.length > 0 &&
      this.props.showUploadedFiles;

    return (
      <div>
        <div className="col-xs-12 col-sm-12" id="ct-media-block">
          <FileInput onInput={this.inputFiles} readFolders={false} hideSelected>
            <FormattedMessage id="UPLOAD_FILE_BTN" />
          </FileInput>
        </div>

        {filesUploaded ? (
          <div style={{ marginTop: "12px" }} className="col-xs-12 col-sm-4">
            <h6 style={{ fontWeight: 900 }}>
              <FormattedMessage id="CBCT_UPLOADED_HEADER" />
            </h6>
            <PatientFiles type={FileType.DICOM} />
          </div>
        ) : null}
      </div>
    );
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
export default connector(PatientUpdateInstructionsUploadCt);
